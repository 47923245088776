<template>
  <div>
    <b-overlay :show="loading">
        <b-table-simple sticky-header class="tg mt-3" bordered striped hover small caption-top responsive :emptyText="$t('globalTrans.noDataFound')">
          <caption class="bg-primary text-light" style="color: black">{{ $t('complain_management.complian_details_new') }}</caption>
            <b-tr>
              <b-th style="font-size: 14px;" class="text-left">{{ $t('globalTrans.division') }}</b-th>
              <td>{{ $i18n.locale === 'bn' ? item.body.division_bn : item.body.division_en }}</td>
              <b-th style="font-size: 14px;" class="text-left">{{ $t('globalTrans.district') }}</b-th>
              <td>{{ $i18n.locale === 'bn' ? item.body.district_bn : item.body.district_en }}</td>
            </b-tr>
            <b-tr>
              <b-th style="font-size: 14px;" class="text-left">{{ $t('globalTrans.upazila') }}</b-th>
              <td>{{ $i18n.locale === 'bn' ? item.body.upazilla_bn : item.body.upazilla_en }}</td>
              <b-th style="font-size: 14px;" class="text-left">{{ $t('priceMonitoring.market_name') }}</b-th>
              <td>{{ $i18n.locale === 'bn' ? item.body.market_bn : item.body.market_en }}</td>
            </b-tr>
            <b-tr>
              <b-th style="font-size: 14px;" class="text-left">{{ $t('bazarMonitoring.commodity_name') }}</b-th>
              <td>{{ $i18n.locale === 'bn' ? item.body.commodity_bn : item.body.commodity_en }}</td>
              <b-th style="font-size: 14px;" class="text-left">{{ $t('complain_management.name_of_accused') }}</b-th>
              <td>{{ item.name_of_accused }}</td>
            </b-tr>
            <b-tr>
              <b-th style="font-size: 14px;" class="text-left">{{ $t('complain_management.name_of_accused_address') }}</b-th>
              <td>{{ item.name_of_accused_address }}</td>
              <b-th style="font-size: 14px;" class="text-left">{{ $t('complain_management.complaint_of_description') }}</b-th>
              <td>{{ item.complaint_of_description }}</td>
            </b-tr>
          </b-table-simple>
          <b-table-simple sticky-header class="tg mt-3" bordered striped hover small caption-top responsive :emptyText="$t('globalTrans.noDataFound')">
            <!-- <caption style="color: black">{{ $t('complain_management.ovijogkari_desc') }}</caption> -->
            <caption class="bg-primary text-light" style="color: black">{{ $t('complain_management.ovijogkari_desc') }}</caption>

            <b-tr>
              <b-th style="font-size: 14px;" class="text-left">{{ $t('complain_management.ovijogkari_name') }}</b-th>
              <td>{{ $i18n.locale === 'bn' ? item.complainant_s_name : item.complainant_s_name }}</td>
              <b-th style="font-size: 14px;" class="text-left">{{ $t('globalTrans.mobile_no') }}</b-th>
              <td>{{ (($i18n.locale === 'bn') ? '০' : '0') + '' + $n(item.mobile, { useGrouping: false }) }}</td>
            </b-tr>
            <b-tr>
              <b-th style="font-size: 14px;" class="text-left">{{ $t('globalTrans.nid') }}</b-th>
              <td>{{ item.nid ? $n(item.nid, { useGrouping: false }) : '' }}</td>
              <b-th style="font-size: 14px;" class="text-left">{{ $t('globalTrans.email') }}</b-th>
              <td>{{ item.email }}</td>
            </b-tr>
            <b-tr>
              <b-th style="font-size: 14px;" class="text-left">{{ $t('globalTrans.occupation') }}</b-th>
              <td>{{ item.occupation }}</td>
              <b-th style="font-size: 14px;" class="text-left">{{ $t('complain_management.asking_price') }}</b-th>
              <td>{{ $n(item.complaint_amount, { useGrouping: false }) }}</td>
            </b-tr>
          </b-table-simple>
        <b-row class="text-right">
          <b-col>
              <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
    </b-overlay>
  </div>
</template>
<script>
export default {
  name: 'Form',
  props: ['id', 'item'],
  data () {
    return {
      valid: null,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      commodityGroup: {
        commodity_group_name_en: '',
        commodity_group_name_bn: '',
        description_en: '',
        description_bn: '',
        price_category_id: []
      }
    }
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    }
  },
  methods: {
    getMarketName (id) {
      const marketDirectory = this.$store.state.BazarMonitoringService.commonObj.marketDirectoryList.find(item => item.value === id)
      return marketDirectory !== undefined ? this.$i18n.locale === 'bn' ? marketDirectory.text_bn : marketDirectory.text_en : ''
    }
  }
}
</script>
